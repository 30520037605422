import { useState, useMemo, useEffect } from 'react'
const JsSearch = require('js-search')
import { tokenizer } from './tokenizer'
import {
  IndexManifest,
  CreateIndexArgs,
  Grabber,
  IndexCache,
  IndexStrategy,
  DataCacheValue
} from './types'

const grab = async (url: string): Promise<DataCacheValue> => {
  if (!window) {
    throw new Error('no window, no go')
  }

  return new Promise((resolve, reject) => {
    const request = new window.XMLHttpRequest()
    request.open('GET', url, true)

    request.onload = function () {
      if (this.status >= 200 && this.status < 400) {
        resolve(JSON.parse(this.response))
      } else {
        reject(new Error(`status code ${this.status}`))
      }
    }

    request.onerror = function () {
      reject(new Error(`connection error of some sort`))
    }

    request.send()
  })
}

const cachedGrab: Grabber = (() => {
  const cache: IndexCache = {}

  return (url: string): Promise<DataCacheValue> => {
    return new Promise<DataCacheValue>(resolve => {
      if (cache[url]) {
        if (cache[url].data) {
          resolve(cache[url].data)
        } else {
          cache[url].subs.push(resolve)
        }
      } else {
        cache[url] = { subs: [] }

        grab(url).then(data => {
          cache[url].data = data
          cache[url].subs.forEach(x => x(data))
          cache[url].subs = []

          resolve(data)
        })
      }
    })
  }
})()

const grabIndex = async (indexName: string): Promise<CreateIndexArgs> => {
  const { indexes = {} }: IndexManifest =
    <IndexManifest>await cachedGrab('/js-search-manifest.json')

  const filename = indexes[indexName]

  const blob = <CreateIndexArgs>await cachedGrab(filename)

  return blob
}

export const useSearch = (indexName: string) => {
  const [blob, setBlob] = useState<CreateIndexArgs | undefined>()

  const index = useMemo(() => {
    return blob ? createIndex(blob) : null
  }, [blob])

  useEffect(() => {
    if (!blob) {
      grabIndex(indexName).then(setBlob)
    }
  }, [blob])

  return index
}

export const createIndex = (obj: CreateIndexArgs) => {
  const { fields, idField = 'id', indexStrategy = IndexStrategy.ExactWord, documents } = obj

  const search = new JsSearch.Search(idField)

  search.tokenizer = tokenizer

  search.indexStrategy = new JsSearch[indexStrategy]()
  fields.forEach(f => search.addIndex(f))

  search.addDocuments(documents)

  return search.search.bind(search)
}
