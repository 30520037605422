import {
  PluginOptions as GatsbyPluginOptions,
  Node
} from 'gatsby'

export enum IndexStrategy {
  Prefix = 'PrefixIndexStrategy',
  AllSubstrings = 'AllSubstringsIndexStrategy',
  ExactWord = 'ExactWordIndexStrategy',
}

export type ArrayFn<T, X> = (item?: T, index?: number, array?: T[]) => X

export interface CreateIndexArgs {
  fields: string[]
  idField?: string
  indexStrategy?: IndexStrategy
  documents: object[]
}

export interface GetIndexDataParams {
  nodeTypes: string[]
  filter?: ArrayFn<Node, boolean>
  mapper: ArrayFn<Node, object>
}

export type FileWriter = (filename: string, data: string) => Promise<string>

export interface IndexArgs extends GetIndexDataParams {
  // name of the index - used for querying for the appropriate index
  name: string
  // name of the field that will be used as the unique identifier
  idField?: string
  /**
   * one of:
   * - PrefixIndexStrategy
   * - AllSubstringsIndexStrategy
   * - ExactWordIndexStrategy
   */
  indexStrategy?: IndexStrategy,
  // search fields to be indexed
  fields: string[]
}

export interface PluginOptions extends GatsbyPluginOptions {
  indexes: Array<IndexArgs>
}

type IndexManifestEntry = {
  [key: string]: string
}
export interface IndexManifest {
  indexes: IndexManifestEntry
}

export interface SearchIndex {
  fields: string[]
  idField: string
  indexStrategy: IndexStrategy
  documents: object[]
}

export type DataCacheValue = SearchIndex | IndexManifest

export type Grabber = (url: string) => Promise<DataCacheValue>
export type Data<T> = T

type dataResolver = (data: Data<DataCacheValue>) => void

interface IndexCacheEntry {
  data?: DataCacheValue
  subs: dataResolver[]
}

export type IndexCache = {
  [key: string]: IndexCacheEntry
}
