import { graphql as gql, useStaticQuery } from 'gatsby'
import '@src/data/prismic-fragments/footer'
import { IconType, FooterType } from '@islandsstofa/react'

interface PrismicFooterRaw {
  data: {
    first_row_title: string
    second_row_title: string
    social_media: {
      link: {
        url: string
      }
      platform: IconType
    }[]
    localLinks: {
      link: {
        url: string
        document: {
          uid: string
          data: {
            page_title: {
              text: string
            }
          }
        }
      }
    }[]
    externalLinks: {
      link: {
        url: string
      }
      link_text: string
    }[]
    address?: string
    tel?: string
  }
}

export default () => {
  const data: {
    prismicFooter: PrismicFooterRaw
  } = useStaticQuery(gql`
    {
      prismicFooter {
        ...prismicFooterFragment
      }
    }
  `)

  if (!data) {
    return null
  }

  const cleanedData: FooterType = {
    localLinks: data.prismicFooter.data.localLinks.map(item => {
      return {
        link: {
          url: item.link.url,
          txt: item.link.document.data.page_title.text
        }
      }
    }),
    externalLinks: data.prismicFooter.data.externalLinks.map(item => {
      return {
        link: {
          url: item.link.url,
          txt: item.link_text
        }
      }
    }),
    socialMedia: data.prismicFooter.data.social_media.map(item => {
      return {
        link: {
          url: item.link.url
        },
        platform: item.platform
      }
    }),
    contactData: {
      address: data.prismicFooter.data.address,
      tel: data.prismicFooter.data.tel
    },
    localLinksTitle: data.prismicFooter.data.first_row_title,
    externalLinksTitle: data.prismicFooter.data.second_row_title
  }

  return cleanedData
}
