import { useLayoutEffect } from 'react'

const BLUR_INTENSITY = '20px'

export default () => {
  useLayoutEffect(() => {
    // Prevent scrolling on mount
    document.body.style.overflow = 'hidden'
    document.getElementById(
      'main-content'
    )!.style.filter = `blur(${BLUR_INTENSITY})`
    // Re-enable scrolling when component unmounts
    return () => {
      document.body.style.overflow = 'visible'
      document.getElementById('main-content')!.style.filter = 'blur(0px)'
    }
  }, []) // Empty array ensures effect is only run on mount and unmount
}
