import React from 'react'

export default ({ ...props }) => (
  <svg
    className='burger'
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M17.5 6H2.5C2.224 6 2 5.776 2 5.5C2 5.224 2.224 5 2.5 5H17.5C17.776 5 18 5.224 18 5.5C18 5.776 17.776 6 17.5 6Z'
      fill='black'
    />
    <path
      d='M17.5 11H2.5C2.224 11 2 10.776 2 10.5C2 10.224 2.224 10 2.5 10H17.5C17.776 10 18 10.224 18 10.5C18 10.776 17.776 11 17.5 11Z'
      fill='black'
    />
    <path
      d='M17.5 16H2.5C2.224 16 2 15.776 2 15.5C2 15.224 2.224 15 2.5 15H17.5C17.776 15 18 15.224 18 15.5C18 15.776 17.776 16 17.5 16Z'
      fill='black'
    />
  </svg>
)
