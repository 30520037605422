import React from 'react'
import { tokenize, useSearch } from '@kogk/gatsby-plugin-js-search'
import { useLanguage } from '@kogk/gatsby-plugin-i18n'
import { getHighestRankedText } from '@src/utils/search'
import { Link } from '@kogk/gatsby-theme-base'
import cn from 'classnames'
import styles from './SearchResultsUi.module.scss'

const MatchedText = ({ term, texts, ...rest }) => {
  if (!texts) {
    return null
  }

  const wordRegexes = tokenize(term).map(word => new RegExp(`(${word})`, 'gi'))
  const highestRankedText = getHighestRankedText(texts, wordRegexes)

  if (!highestRankedText) {
    const [first] = texts
    if (!first) {
      return null
    }
    return first
  }

  const __html = wordRegexes.reduce(
    (html, regex) => html.replace(regex, match => `<strong>${match}</strong>`),
    highestRankedText
  )

  return (
    <p
      className='parag'
      dangerouslySetInnerHTML={{
        __html
      }}
      {...rest}
    />
  )
}

const SearchResultsUi: React.FC<{ term: string; className?: string }> = ({
  term,
  className
}) => {
  const { language } = useLanguage()
  const search = useSearch(language)

  if (!search) {
    return null
  }

  return (
    <ol className={className}>
      {search(term).map(result => (
        <Link key={result.url} to={result.url}>
          <li className={cn('py-3', styles.resultBox)}>
            <h4 className='mb-2'>{result.title}</h4>
            <MatchedText
              className='m-0 parag'
              term={term}
              texts={result.text}
            />
          </li>
        </Link>
      ))}
    </ol>
  )
}

export default SearchResultsUi
