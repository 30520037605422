import { MenuItemType } from '@islandsstofa/react'
import React, { useContext } from 'react'
import { Link } from 'gatsby'
import cn from 'classnames'
import { MobileMenuContext } from '@root/src/context/mobileMenuContext'
import { Fade } from 'react-reveal'

const MobileDropdown: React.FC<{ expand: boolean; items: MenuItemType[] }> = ({
  expand,
  items
}) => {
  const { close: closeMobileMenu } = useContext(MobileMenuContext)
  return (
    <div
      className={cn('mobile-dropdown', {
        'mobile-dropdown--expanded': expand
      })}
    >
      {items.map(item => (
        <Fade when={expand} key={item.url} top distance={'10px'} duration={250}>
          <Link
            to={item.url}
            activeClassName='activeMenuItem'
            className={cn('d-flex align-items-center mobile-dropdown__item')}
            onClick={() => closeMobileMenu()}
          >
            <h5 className='m-0 d-flex justify-content-between pl-3'>
              {item.title}
            </h5>
          </Link>
        </Fade>
      ))}
    </div>
  )
}

export default MobileDropdown
