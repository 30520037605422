import React from 'react'
import useGetSeoProperties from './useGetSeoProperties'
import { Helmet } from 'react-helmet'

const SEO = () => {
  const { description, image, title, keywords } = useGetSeoProperties()
  return (
    <Helmet>
      <title>{title}</title>
      <meta property='description' content={description} />
      <meta property='keywords' content={keywords} />
      <meta property='og:image' content={image.url} />
      <meta property='og:description' content={description} />
      <meta name='twitter:description' content={description} />
      <meta name='twitter:image' content={image.url} />
      <meta name='twitter:card' content='summary_large_image' />
    </Helmet>
  )
}

export default SEO
