import React, { useContext } from 'react'
import { Burger, Close } from '@islandsstofa/react/src/Icon/SVGS'
import { MobileMenuContext } from '@root/src/context/mobileMenuContext'

const NewBurger = () => {
  const { isOpen, open, close } = useContext(MobileMenuContext)
  return (
    <div>
      {isOpen ? (
        <Close onClick={() => close()} />
      ) : (
        <Burger style={{ marginRight: '5px' }} onClick={() => open()} />
      )}
    </div>
  )
}

export default NewBurger
