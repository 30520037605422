import React from 'react'

export default ({ ...props }) => (
  <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <circle cx='16' cy='16' r='15.5' stroke='#B7B7B7' />
    <path
      d='M20.8325 20.0289L16.7525 16.0001L20.8325 11.9712C21.0385 11.7679 21.0575 11.4168 20.8745 11.1868C20.6915 10.9568 20.3755 10.9368 20.1685 11.1401L16.0005 15.2567L11.8325 11.1401C11.6265 10.9368 11.3105 10.9568 11.1265 11.1868C10.9425 11.4168 10.9615 11.7668 11.1685 11.9712L15.2485 16.0001L11.1685 20.0289C10.9625 20.2322 10.9435 20.5833 11.1265 20.8133C11.2255 20.9367 11.3625 21 11.5005 21C11.6185 21 11.7375 20.9533 11.8325 20.86L16.0005 16.7434L20.1685 20.86C20.2635 20.9544 20.3825 21 20.5005 21C20.6385 21 20.7755 20.9367 20.8745 20.8133C21.0575 20.5845 21.0395 20.2333 20.8325 20.0289Z'
      fill='#0D0D0D'
    />
  </svg>
)
