import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import { Icon, Dropdown, MenuItemType } from '@islandsstofa/react'
import cn from 'classnames'
import { useLocation } from '@reach/router'

const NavItem = ({ item }: { item: MenuItemType }) => {
  const [expandDropdown, setExpandDropdown] = useState(false)

  const displayAsDropdown = item.subMenu && !!item.subMenu.length

  const { pathname } = useLocation()

  const thisPath =
    pathname[pathname.length - 1] !== '/' ? pathname.concat('/') : pathname

  const isCurrentPage =
    item.url === thisPath ||
    (item.subMenu && item.subMenu.some(menuItem => menuItem.url === thisPath))

  const [isActive, setIsActive] = useState(false)

  useEffect(() => setIsActive(!!isCurrentPage), [isCurrentPage])

  const NavItemContent = () => {
    return (
      <>
        <span
          className={cn('nav-item__top-line', {
            'nav-item__top-line--visible': isActive || isCurrentPage
          })}
        />
        <h6 className='m-0 text-center d-flex'>
          {item.title}
          {displayAsDropdown && (
            <Icon containerClass='nav-item__anchor__icon' type='arrowDown' />
          )}
        </h6>
      </>
    )
  }

  return (
    <div
      className='nav-item position-relative'
      onMouseEnter={() => {
        setExpandDropdown(true)
        setIsActive(true)
      }}
      onMouseLeave={() => {
        setExpandDropdown(false)
        setIsActive(false)
      }}
    >
      {item.url ? (
        <Link
          activeClassName='nav-item--active'
          partiallyActive
          className='nav-item__anchor'
          to={item.url}
        >
          <NavItemContent />
        </Link>
      ) : (
        <button
          className='nav-item__anchor'
          onClick={() => setExpandDropdown(!expandDropdown)}
        >
          <NavItemContent />
        </button>
      )}

      {displayAsDropdown && (
        <Dropdown
          expand={expandDropdown}
          items={item.subMenu}
          onRenderItem={(item, key) => (
            <Link
              key={key}
              to={item.url}
              className='dropdown__item'
              activeClassName='dropdown__item--active'
            >
              <h6>{item.title}</h6>
            </Link>
          )}
        />
      )}
    </div>
  )
}

export default NavItem
