import React, { useContext } from 'react'
import { MobileMenuContext } from '@root/src/context/mobileMenuContext'
import cn from 'classnames'
import { Search } from '@cmp/site/SearchResults/SearchResults'
import { Container } from '@kogk/common'
import { useGlobalData } from '@root/src/data/global-data'
import { Link } from 'gatsby'
import { IconButton, MenuItemType } from '@islandsstofa/react'
import Dropdown from '@cmp/site/MobileDropdown'

const NavItem: React.FC<{ item: MenuItemType }> = ({ item }) => {
  if (!item.subMenu) return null

  const displayAsDropdown = !!item.subMenu.length

  const {
    close,
    secondLevelDropdownExpanded,
    triggerSecondLevelDropdown
  } = useContext(MobileMenuContext)

  const expandDropdown = secondLevelDropdownExpanded.includes(item.url)

  return (
    <>
      <div
        className={cn(
          'mobile-menu__item',
          'd-flex align-items-center position-relative',
          { 'mb-1': !expandDropdown }
        )}
      >
        <Link
          activeClassName='activeMenuItem'
          className='h-100 w-100 d-flex align-items-center'
          to={item.url}
          onClick={() => close()}
        >
          <Container>
            <h4 className='m-0 d-flex justify-content-between'>{item.title}</h4>
          </Container>
        </Link>
        {displayAsDropdown && (
          <IconButton
            onClick={() => triggerSecondLevelDropdown(item.url)}
            className={cn('mobile-menu__icon', {
              'mobile-menu__icon--turn-down': expandDropdown
            })}
            type='arrowDown'
          />
        )}
      </div>
      {displayAsDropdown && (
        <Dropdown expand={expandDropdown} items={item.subMenu} />
      )}
    </>
  )
}

const MobileMenu = () => {
  const { isOpen } = useContext(MobileMenuContext)
  const { mainMenu } = useGlobalData()
  return (
    <div
      className={cn(
        'mobile-menu',
        { 'mobile-menu--open': isOpen },
        'd-block d-lg-none'
      )}
    >
      <Container>
        <Search isMobile />
      </Container>
      <div>
        {mainMenu.map((item: MenuItemType) => (
          <NavItem key={item.url} item={item} />
        ))}
      </div>
    </div>
  )
}

export default MobileMenu
