/**
 * honestly, this is probably not a good idea, but we have
 * a very limited use case for now
 * @param {string} query
 */
export const parseQueryString: (query: string) => {} = query => {
  const [str] = query.split('?').reverse()

  return str
    .split('&')
    .map(pair => pair.split('='))
    .reduce(
      (acc, [key, val]) => ({
        ...acc,
        [decodeURIComponent(key)]: decodeURIComponent(val)
      }),
      {}
    )
}

const computeScore = (text, wordRegexes) => {
  return wordRegexes
    .map(regex => countMatches(text, regex))
    .map(count => Math.sqrt(count))
    .reduce((sum, count) => sum + count, 0)
}

const countMatches = (text, regex) => {
  const matches = text.match(regex)

  return matches ? matches.length : 0
}

export const getHighestRankedText = (texts, wordRegexes) => {
  const { text = null } = texts
    .map(text => ({
      text,
      score: computeScore(text, wordRegexes)
    }))
    .reduce((best, curr) => (curr.score > best.score ? curr : best), {
      score: -1
    })

  return text
}
